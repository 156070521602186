<template>
  <section v-if="showmenu">
    <div v-if="!fetching && $auth.ready() && allbooks">
      <h1 class="title">Holdings tree under {{ top }}</h1>
      <p class="is-size-6"><router-link v-if="!top.match(/00$/)" :to="{name: 'ByClassification', params: {id: getParent(top)}}">Go up to browse {{ getParent(top) }}</router-link></p>
      <div v-for="code in Object.keys(allbooks)" v-bind:key="code">
        <div class="column">

            <h1 class="subtitle">Holdings under class <router-link :to="{name: 'ByClassification', params: {id: code}}">{{ code }}</router-link> ::
              <em>{{ titleForCode(code) }}</em></h1>
        
          <!-- <h2 class="subtitle" v-if="dewey">{{ dewey.attributes.name }}</h2> -->
        </div>
        <div class="columns">
          <div class="column">
            <div class="content has-text-left">
              <table class="table" v-if="allbooks[code].data.length > 0">
                <thead>
                  <tr>
                    <th>Old cat#</th>
                    <th>&nbsp;</th>
                    <th>Title</th>
                    <th>Author</th>
                    <th>Details</th>
                    <th>Publisher</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-bind:key="book.id" v-for="book in allbooks[code].data" :class="statuses[book.attributes.status_id].replace(/\s/g, '').toLowerCase()">
                    <td class="category" >
                      Old: {{ book.attributes.section }}<br />
                      {{ book.attributes.catno }}
                      <p><strong>New: {{ book.attributes.call_number }}</strong></p>
                    </td>
                    <td>
                      <router-link :to="{name: 'Book', params: { id: book.id }}"><img v-if="book.attributes.image_url != '/book_missing.png'" :src="book.attributes.image_url" />
                      </router-link>
                    </td>
                    <td class="book_title">{{ book.attributes.title }}</td>
                    <td class="book_author is-size-6">{{ book.attributes.author }}</td>
                    <td class="is-size-7">{{ book.attributes.isbn13 }}<br />
                        {{ book.attributes.language }}<br />
                        {{ book.attributes.isbn10 }}
                        {{ book.attributes.year_published }}<br />
                        {{ book.attributes.pages }}
                    </td>
                    <td class="is-size-7">{{ book.attributes.publisher }}</td>
                    <td>
                      {{ statuses[book.attributes.status_id] }}
                      <router-link :to="{name: 'Book', params: { id: book.id }}">
                        <button class="button">Details</button>
                      </router-link>
        
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <img src="@/assets/ajax-loader.gif"/>
    </div>
  </section>

</template>
<script>
  import normalize from 'json-api-normalizer'

export default {
  data () {
    return {
      ddc: this.$route.params.id,
      fetching: true,
      showmenu: true,
      allbooks: [],
      top: this.$route.params.id,
      dewey: {},
      sections: [],
      statuses: {
        1: 'On shelf',
        2: 'Borrowed',
        3: 'Missing'
      }
    }
  },
  name: 'ByClassification',
  methods: {
    titleForCode(code, precedent) {
      if (code.match(/00$/)) {
        return this.titleForCode(code + ".0", precedent)
        // return precedent +  code +"::" + ' >> ' + this.dewey.filter((x) => x["attributes"]["code"] === code )[0]["attributes"]["name"]
      } 
      else if (code.match(/\.0$/)) {
        
        let dwy = this.dewey.filter((x) => x["attributes"]["code"] === code )
        if (dwy.length > 0) {
          return dwy[0]["attributes"]["name"] + (precedent ? precedent : '')
        } else {
          return "Category unknown" + (precedent ? (" > " + precedent) : '')
        }
      }
      else {
        let dwy = this.dewey.filter((x) => x["attributes"]["code"] === code )
        if (dwy.length > 0) {
          return this.titleForCode(this.getParent(code), " > " + dwy[0]["attributes"]["name"] +  (precedent ?  (" > " + precedent) : '') )
        } else {
          return this.titleForCode(this.getParent(code),  " > " +" Category unknown" + (precedent ? (" > " + precedent) : ''))
        }
      }
    },
    getParent(classification) {
      let str = classification.toString()
      let parent = ''
      if (!str.match(/\./)) {
        if (str.match(/0$/)) {
          parent = str.slice(0, -2) + "00"
          
        } else {
          parent =  str.slice(0, -1) + "0"
        }
      } else {
        if (str.match(/\.0$/)) {
          parent = str.slice(0, -3) +   "0"
        } else {
          parent = str.slice(0, -1)
        }
      }

      if (parent.match(/\.$/)) {
        parent = parent + '0'
      }
     
      return parent
    }
  },
  mounted () {
    this.$attrs.searchTerm = null
    this.axios.post('/v1/deweys/books',  {code: this.$route.params.id})
    .then((response) => {
      this.allbooks = response.data.books
      this.dewey = response.data.classification.data
      this.fetching = false
    })
  }
}
</script>